<template>
  <div>
    <b-card>
      <b-button
        variant="primary"
        size="sm"
        v-b-modal.request-overtime
        v-if="getRole() != 'director'"
      >
        Request Overtime
      </b-button>

      <b-modal
        id="request-overtime"
        size="md"
        title="Request Overtime"
        hide-footer
      >
        <form @submit.prevent="submit_form">
          <b-row>
            <b-col md="6">
              <b-form-group label="Start">
                <flat-pickr
                  v-model="start"
                  :config="config"
                  placeholder="Start Date"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="End">
                <flat-pickr
                  v-model="end"
                  :config="config"
                  placeholder="End Date"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="Reason">
            <b-form-textarea
              id="reason"
              v-model="reason"
              placeholder="Reason"
            />
          </b-form-group>

          <div class="text-right mt-2">
            <b-button
              variant="outline-danger"
              @click="$bvModal.hide('request-overtime')"
              class="mr-1"
            >
              Cancel
            </b-button>
            <b-button variant="primary" type="submit"> Submit </b-button>
          </div>
        </form>
      </b-modal>

      <div class="d-flex justify-content-between align-items-center mb-1 mt-1">
        <div class="d-flex align-items-center">
          <label for="" class="mr-1" style="font-size: 14px">Sort</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-100"
          />
          <download-excel
            :data="exportItems"
            :fields="exportFields"
            :filename="'Employee'"
            :sheetname="'Employee'"
            class="cursor-pointer ml-1"
          >
            <b-button
              variant="outline-secondary"
              size="sm"
              class="d-flex mr-1 export-btn"
            >
              <feather-icon icon="FileIcon" size="14" class="text-success" />
              <label style="font-size: 14px; margin-left: 5px; cursor: pointer"
                >Export</label
              >
            </b-button>
          </download-excel>
        </div>
        <div class="d-flex align-items-center">
          <label for="" class="mr-1" style="font-size: 14px">Search</label>
          <b-form-input
            id="searchInput"
            v-model="filter"
            size="sm"
            type="search"
            placeholder="Search"
          />
        </div>
      </div>

      <b-row>
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            @filtered="onFiltered"
            class="header-wrap"
          >
            <template #cell(profile_photo_url)="data">
              <b-avatar :src="data.value" />
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(actions)="data">
              <feather-icon icon="EditIcon" v-b-modal="`edit_${data.value}`" />
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Showing
              {{ perPage > totalRows ? totalRows : perPage }} of
              {{ totalRows }} entries
            </span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      v-for="item in items"
      :key="item"
      :id="`edit_${item.id}`"
      size="sm"
      title="Approve"
      hide-footer
    >
      Are you sure you want to approve this overtime?

      <div class="text-right mt-2">
        <b-button
          variant="outline-danger"
          class="mr-1"
          @click="reject(item.id)"
          size="sm"
        >
          Reject
        </b-button>
        <b-button variant="primary" @click="approve(item.id)" size="sm">
          Approve
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<style>
.header-wrap {
  white-space: nowrap !important;
}

.export-btn {
  height: 2.142rem;
  background: #fff !important;
  border: 1px solid #d8d6de !important;
}
</style>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      fields: [
        {
          key: "employee",
          label: "Employee",
        },
        {
          key: "start_time",
          label: "Start Time",
        },
        {
          key: "end_time",
          label: "End Time",
          sortable: "true",
        },
        {
          key: "reason",
          label: "Reason",
          sortable: "true",
        },
        {
          key: "approver",
          label: "Approver",
          sortable: "true",
        },
        { key: "status", label: "Status", sortable: "true" },
        { key: "actions", label: "Actions" },
      ],
      items: [],
      status: [
        {
          pending: "Pending",
          approved: "Approved",
          rejected: "Rejected",
        },
        {
          pending: "light-warning",
          approved: "light-success",
          rejected: "light-danger",
        },
      ],

      // export excel
      exportFields: {
        Employee: "employee",
        "Start Time": "start_time",
        "End Time": "end_time",
        Reason: "reason",
        Approver: "approver",
        Status: "status",
      },
      exportItems: [],

      // config v-select
      config: {
        altInput: true,
        enableTime: true,
      },

      // modal
      subject: "",
      employee: "",
      type: "",
      number_of_days: "",
      start: "",
      end: "",
      handover: "",
      reason: "",
      file: "",

      employees: [],
      types: [],
      handovers: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    // fetch data
    this.fetchData();
  },

  methods: {
    fetchData() {
      let params = {};

      if (this.getRole() == "staff") {
        params = {
          user_id: this.hashid(
            JSON.parse(localStorage.getItem("sw_auth_data")).id
          ),
        };
      } else {
        params = {
          staff: 1,
        };
      }

      this.callApi({
        method: "GET",
        url: "/overtimes/fetch",
        params: params,
        success: (response) => {
          response.result.forEach((item) => {
            this.items.push({
              id: item.id,
              employee: item.user.name,
              start_time: item.start_time,
              end_time: item.end_time,
              reason: item.reason,
              approver: item.approver.name,
              status: item.status,
              actions: item.id,
            });

            this.exportItems.push({
              employee: item.user.name,
              start_time: item.start_time,
              end_time: item.end_time,
              reason: item.reason,
              approver: item.approver.name,
              status: item.status,
            });
          });
          //
          this.totalRows = this.items.length;
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to fetch data",
              variant: "error",
            },
          });
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteEmployee(id) {
      this.callApi({
        method: "DELETE",
        url: "/users/delete/" + this.hashid(id),
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Delete successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to delete",
              variant: "error",
            },
          });
        },
      });
    },

    submit_form() {
      this.callApi({
        method: "POST",
        url: "/overtimes/create",
        data: {
          user_id: JSON.parse(localStorage.getItem("sw_auth_data")).id,
          reason: this.reason,
          start_time: this.start,
          end_time: this.end,
          status: "pending",
        },
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Create successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
          this.$bgModal.hide("request-overtime");
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to create",
              variant: "error",
            },
          });
        },
      });
    },

    approve(id) {
      this.callApi({
        method: "POST",
        url: `overtimes/update/${this.hashid(id)}`,
        data: {
          id: id,
          status: "approved",
        },
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Approve successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
          this.$bvModal.hide("approve-overtime");
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to approve",
              variant: "error",
            },
          });
        },
      });
    },

    reject(id) {
      this.callApi({
        method: "POST",
        url: `overtimes/update/${this.hashid(id)}`,
        data: {
          id: id,
          status: "rejected",
        },
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Reject successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
          this.$bvModal.hide("reject-overtime");
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to reject",
              variant: "error",
            },
          });
        },
      });
    },
  },
};
</script>